import './index.sass';
import 'regenerator-runtime/runtime'


import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
UIkit.use(Icons);

 //UIkit.notification('Hello world.');

$( document ).ready(function() {



        AjaxForm.Message.success = function(message, sticky) {
            UIkit.modal('#tnx').show();
            setTimeout(cl, 4000);
        };

        function cl() {
            UIkit.modal('#tnx').hide();
        }


    $('.one-click-modal-open').click(function(){
        let v = $('.volume_parent input:checked').val()
        $('#one-volume').val(v)
        let p = $('.price_parent .msoptionsprice-cost').text()
        $('.one-price_inp').val(p)
        $('#one-price_inp').text(p)
    })

 // const text = 'Push me';
//  const satisfaction = `${text}, and then just touch me`;

 // (() =>console.log(satisfaction))();


  $(".plus").click(function () {
    let t = $(this).prev("input").val();
    $(this).prev("input").val(Number(t) + 1), $(this).prev("input").trigger("change");
  }), $(".minus").click(function () {
    let t = $(this).next("input").val();
    t > 1 && $(this).next("input").val(Number(t) - 1), $(this).next("input").trigger("change");
  }), $(".volume_parent").on("click", ".input-parent", function () {});





});

$( ".uk-container" ).on( "click", ".filtr-left_button", function() {
    let arr = [];
    let i = 0;
    $('.filtr-left_checkbox').each(function () {
        if (this.checked) {
            arr[i++] = $(this).val();
        }
    });
    let url =  $(this).data('url')
    window.location.href = url+'?tags='+arr.join()
})



$('.uk-search-input').keydown(function (){
    if($(this).val() != '') {
        $(this).addClass('action-search');
    }
    else {
        $(this).removeClass('action-search');
    }
})

if($('.uk-search-input').val() != '') {
    $('.uk-search-input').addClass('action-search');
}

function sayTags() {
    let params = (new URL(document.location)).searchParams
    let tags =  params.get("tags")
    if(tags) {
        let ar = params.get("tags").split(',')
        ar.forEach(element => $( ".filtr-left_checkbox[value="+element+"]" ).prop("checked",true) )
        // $('.filtr-left_head').addClass('uk-open')
    }
}

setTimeout(sayTags, 1000);


$( ".my-filter" ).change( function() {
    let val = $(this).val()
    let url =  $(this).data('url')
    window.location.href = url+'?'+val
    return false
})

$( ".uk-container" ).on( "click", ".heart", function() {
  let idItem = $(this).data('id');
  let heart = 1;

  if($(this).hasClass( "active" )) {
    heart =  0;
    $(this).removeClass( "active" )
  }
  else {
    $(this).addClass( "active" )
  }

   $.ajax({
    method: "POST",
    url: "/",
    data: { action: "heart", idiem: idItem, heart: heart}
  }).done(function( msg ) {
       console.log(msg)
   });
 return false;
});

$(".page-product .radio-custom").click(function () {
     let i = $(this).prev('input').val();
     let opt = '';
  if($(this).prev('input').attr('name') == 'options[volume]') {
     opt = 'volume='+i;
  }
   updateURL(opt);


});

let leftFilter = $('.filtr-left-desktop').html()
widthWindow()
$(window).resize(function() {
    widthWindow();
});

function widthWindow() {

    if ($(window).width() <= '995'){

        $('.filtr-left-mobail').html(leftFilter)
        $('.filtr-left-desktop').html('')
        let ulTab =  $('.tab-product').next('ul').children('li');
        let myObj = {}
        ulTab.each(function( i ) {
            myObj[i] = $( this ).text();
        });
      //  console.log(myObj[0])

     //   myObj = JSON.parse(myObj);


        let list =  $('.uk-accordion-content');
        list.each(function( i ) {
           // myObj[i] =
            $(this).text(myObj[i]);
        });

        $('.add-slider-items').addClass('uk-slider-items')
        return this;}
    else   {
        $('.filtr-left-mobail').html('')
        $('.filtr-left-desktop').html(leftFilter)
        $('.add-slider-items').removeClass('uk-slider-items')
    }
}


function updateURL(parametr) {
  if (history.pushState) {
    let baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    let newUrl = baseUrl + '?'+parametr;
    history.pushState(null, null, newUrl);
    return newUrl;
  }
  else {
    console.warn('History API не поддерживается');
  }
}
